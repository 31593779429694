<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.activitycommon/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="id" title="活动ID" align="center" min-width="20px" />
			<vxe-table-column slot="table-item" field="name" title="名称" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="cfg.platform" title="生效平台" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{$platform[row.cfg.platform||0]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="inviteTxt" title="邀请人配置" align="center" min-width="220px" />
			<vxe-table-column slot="table-item" field="getInviteTxt" title="被邀请人配置" align="center" min-width="220px" />
			<vxe-table-column slot="table-item" field="backTxt" title="回归配置" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="btime" title="开始时间" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="etime" title="结束时间" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="status" title="状态" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{row.status==1?'有效':'无效'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="300px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.activitycommon/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="detail(row)">详情</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 编辑 -->
		<el-dialog title="编辑/新增" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="名称" prop="name">
					<el-input v-model="editDialogFormData.name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="editDialogFormData.cfg.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="电量奖励" prop="cfg">
					<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addCfg">增加档位</el-button><span style="color: red;">档位必须从小到大</span><br/>
					<div v-for="(item,idx) in editDialogFormData.cfg.electric" v-bind:key="idx">
						大于等于<el-input-number type="number" :min="1" :max="1000" :step="1" :precision="0"
							v-model="editDialogFormData.cfg.electric[idx][0]" style="width: 108px;" class="input" size="small" placeholder="电量">
						</el-input-number> 度 <el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="onItemDel(idx)">删除</el-button>
						<div style="padding-left: 20px;">
							受邀人获得 <el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addReword(idx, 1)">增加奖励</el-button>
							<div v-for="idx1 in [0,2,4,6,8]" v-if="editDialogFormData.cfg.electric[idx][1][idx1]!=null" v-bind:key="'sqr'+idx1">
								<el-select v-model="editDialogFormData.cfg.electric[idx][1][idx1]" style="width: 130px;" clearable  class="select" size="small" placeholder="请选择优惠券">
									<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
									</el-option>
								</el-select>
								数量 <el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
									v-model="editDialogFormData.cfg.electric[idx][1][idx1+1]" style="width: 108px;" class="input" size="small" placeholder="数量">
								</el-input-number>
								<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="delReword(idx, 1, idx1)">删除</el-button>
							</div>
							<div>邀请人获得 <el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addReword(idx, 2)">增加奖励</el-button></div>
							<div v-for="idx2 in [0,2,4,6,8]" v-if="editDialogFormData.cfg.electric[idx][2][idx2]!=null" v-bind:key="'yqr'+idx2">
								<el-select v-model="editDialogFormData.cfg.electric[idx][2][idx2]" style="width: 130px;" clearable  class="select" size="small" placeholder="请选择优惠券">
									<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
									</el-option>
								</el-select>
								数量<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
									v-model="editDialogFormData.cfg.electric[idx][2][idx2+1]" style="width: 108px;" class="input" size="small" placeholder="数量">
								</el-input-number>
								<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="delReword(idx, 2, idx2)">删除</el-button>
							</div>							
						</div>
					</div>
				</el-form-item>	
				<el-form-item label="回归奖励" prop="inviteUserBack">
					<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="backRewordAdd()">增加奖励</el-button>
					<div v-for="idx in [0,2,4,6,8]" v-if="editDialogFormData.cfg.inviteUserBack[idx]!=null" v-bind:key="'back'+idx">
						<el-select v-model="editDialogFormData.cfg.inviteUserBack[idx]" style="width: 130px;" clearable  class="select" size="small" placeholder="请选择优惠券">
							<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
							</el-option>
						</el-select>
						数量<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
							v-model="editDialogFormData.cfg.inviteUserBack[idx+1]" style="width: 108px;" class="input" size="small" placeholder="数量">
						</el-input-number>
						<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="backRewordDel(idx)">删除</el-button>
					</div>	
				</el-form-item>			
				<el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime"
						type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime"
						type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="是否有效" prop="status">
					<el-switch v-model="editDialogFormData.status" active-value="1" inactive-value="0" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'

	export default {
		name: 'market-activitycommon-invite',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				// 表格信息
				tableName: '邀请有礼',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [],
				couponList: [],
				selecttime: {},
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					platform: [{
						required: true,
						message: '请选择生效平台',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(!("platform" in this.editDialogFormData.cfg)) return callback(new Error('请选择生效平台'));
							callback();
						}
					}],
					btime: [{
						required: true,
						message: '请输入活动开始时间',
						trigger: 'change'
					}],
					etime: [{
						required: true,
						message: '请输入活动结束时间',
						trigger: 'change'
					}]
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {cfg:{electric:[],inviteUserBack:[]}},
			}
		},
		computed: {
		},
		mounted() {
			//this.getList();
			//获取优惠券列表
			const params = {
				token: this.$store.state.user.token,
				page: 1,
				size: 100,
			};
			this.$api.Market.GetCouponList(params, {}).then((res)=>{
				for (let d of res.data) {
					if (d.max_money != null) {
						d.coupon_name = d.coupon_name + '(' + d.max_money + '元)';
					}
				}
				this.couponList = res.data;				
			});

		},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					tp: 3,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.ActivityCommon.list(params);
				for(let v of res.data){
					v.cfg = JSON.parse(v.cfg);
					v.cfg.platform = v.cfg.platform||0;
					v.inviteTxt = v.getInviteTxt = "";
					for(let d of v.cfg.electric){
						v.inviteTxt += "充电满" + d[0] + "度 ";
						v.getInviteTxt += "充电满" + d[0] + "度 ";
						for(let i=0;i<d[1].length;i+=2){
							v.getInviteTxt += "券[" + d[1][i] + "]数量[" + d[1][i+1] + "]";
						}
						for(let i=0;i<d[2].length;i+=2){
							v.inviteTxt += "券[" + d[2][i] + "]数量[" + d[2][i+1] + "]";
						}
						v.inviteTxt += "\n";
						v.getInviteTxt += "\n";
					}
					v.backTxt = "";
					for(let i=0;i<v.cfg.inviteUserBack.length;i+=2){
						v.backTxt += "券[" + v.cfg.inviteUserBack[i] + "]数量[" + v.cfg.inviteUserBack[i+1] + "]\n";
					}
					v.btime = v.btime.substr(0, 10);
					v.etime = v.etime.substr(0, 10);
				} 
				this.List = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {cfg:{electric:[],inviteUserBack:[]}}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.editDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData();
				row.status = row.status.toString();
				this.editDialogFormData = Object.assign({}, row);
				delete this.editDialogFormData._XID;
				delete this.editDialogFormData.inviteTxt;
				delete this.editDialogFormData.getInviteTxt;
				delete this.editDialogFormData.backTxt;
				this.editDialogShow = true
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Market.ActivityCommon.del(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						let params = {
							token: this.$store.state.user.token,
							form: Object.assign({"tp":3}, this.editDialogFormData)
						}
						params.form.cfg = JSON.stringify(params.form.cfg);
						params.form = JSON.stringify(params.form);
						if(this.editDialogFormData.id > 0){
							await this.$api.Market.ActivityCommon.edit(params);
						}else{
							await this.$api.Market.ActivityCommon.add(params);
						}
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 返券记录
			detail(row) {
				this.$router.push({
					name: "market-activitycommon-invitelog",
					query: {id:row.id},
				});
			},			
			addCfg(){ //添加挡位配置
				this.editDialogFormData.cfg.electric.push([0,[],[]]);
				//console.log(this.editDialogFormData.cfg);
			},
			onItemDel(idx){ //删除挡位配置
				this.editDialogFormData.cfg.electric.splice(idx, 1);
			},
			addReword(idx, tp){ //增加奖励
				this.editDialogFormData.cfg.electric[idx][tp].push("", 1);
			},
			delReword(idx, tp, idx1){ //增加奖励
				this.editDialogFormData.cfg.electric[idx][tp].splice(idx1, 2);
			},
			backRewordAdd(){ //增加奖励
				this.editDialogFormData.cfg.inviteUserBack.push("", 1);
			},
			backRewordDel(idx){ //增加奖励
				this.editDialogFormData.cfg.inviteUserBack.splice(idx, 2);
			},
		}
	}
</script>

<style lang="scss" scoped>
</style>
